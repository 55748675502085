const registryV3abi = require('./registryV3abi.json');

module.exports = {
  "public": {

    // Ethereum
    "1": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "16227060",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.etherscan.io/api",
      "testnet": false
    },
    // Goerli
    "5": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "8110721",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api-goerli.etherscan.io/api",
      "testnet": true
    },
    // Sepolia
    "11155111": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "5652976",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api-sepolia.etherscan.io/api",
      "testnet": true
    },
    // Holesky
    "17000": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "1304936",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api-holesky.etherscan.io/api",
      "testnet": true
    },

    // BSC
    "56": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "24071653",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.bscscan.com/api",
      "testnet": false
    },
    // BSC Testnet
    "97": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "25304946",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api-testnet.bscscan.com/api",
      "testnet": true
    },

    // Polygon
    "137": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "37063635",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.polygonscan.com/api",
      "testnet": false
    },
    // Polygon Mumbai
    "80001": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "39703609",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api-testnet.polygonscan.com/api",
      "testnet": true
    },
    // Polygon Amoy
    "80002": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "9016076",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api-amoy.polygonscan.com/api",
      "testnet": true
    },

    // Avalanche
    "43114": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "23888750",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.snowscan.xyz/api",
      "testnet": false
    },
    // Avalanche Fuji
    "43113": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "25511661",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api-testnet.snowscan.xyz/api",
      "testnet": true
    },

    // Fantom
    "250": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "52588189",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.ftmscan.com/api",
      "testnet": false
    },
    // Fantom Testnet
    "4002": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "20272643",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api-testnet.ftmscan.com/api",
      "testnet": true
    },

    // Arbitrum
    "42161": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "47617675",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.arbiscan.io/api",
      "testnet": false
    },

    // Optimism
    "10": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "97226028",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api-optimistic.etherscan.io/api",
      "testnet": false
    },

    // Cronos
    "25": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "7606507",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.cronoscan.com/api",
      "testnet": false
    },

    // PulseChain
    "369": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "17388844",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "",
      "testnet": false
    },

    // Base
    "8453": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "2001251",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.basescan.org/api",
      "testnet": false
    },

    // Core
    "1116": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "3712191",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "",
      "testnet": false
    },

    // Evmos
    "9001": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "13215784",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "",
      "testnet": false
    },

    // Linea
    "59144": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "1220599",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.lineascan.build/api",
      "testnet": false
    },

    // Mantle
    "5000": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "27152656",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.routescan.io/v2/network/mainnet/evm/5000/etherscan/api",
      "testnet": false
    },

    // Blast
    "81457": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "1623452",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.blastscan.io/api",
      "testnet": false
    },

    // DegenChain
    "666666666": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "1736428",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "",
      "testnet": false
    },

    // Scroll
    "534352": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "6035487",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://api.scrollscan.com/api",
      "testnet": false
    },

    // Metis
    "1088": {
      "rc": "0x7715592be525a8cE67Ec14c2b13Bf50c9Ee10Ba4",
      "rcAbi": registryV3abi,
      "rcBlock": "17179428",
      "IterableMapping": "0x31ee4a53Bd2C1c339662DfFB973017EF81A6bad5",
      "verifyApi": "https://andromeda-explorer.metis.io/api",
      "testnet": false
    }
  },
  "private": {
    // Ethereum
    "1": {
      "url": "https://eth-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://ethereum.blockpi.network/v1/rpc/public",
      "verifyApiKey": "VJ5QB8IKE15XDDKC2MZXPB7X4V4PIZ4ZUJ",
      "storage": "0x205BF1cA117c77da148e8dF8a61C3155AF84FB2e"
    },
    // Goerli
    "5": {
      "url": "https://eth-goerli.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://goerli.blockpi.network/v1/rpc/public",
      "verifyApiKey": "VJ5QB8IKE15XDDKC2MZXPB7X4V4PIZ4ZUJ",
      "storage": "0xdDCd5e8141c2c151b9731B67A9955c3bC3400b91"
    },
    // Sepolia
    "11155111": {
      "url": "https://eth-sepolia.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://ethereum-sepolia-rpc.publicnode.com",
      "verifyApiKey": "VJ5QB8IKE15XDDKC2MZXPB7X4V4PIZ4ZUJ",
      "storage": "0xdEf4e82De3932f93f93ce18Fe12C89495e6D5156"
    },
    // Holesky
    "17000": {
      "url": "https://eth-holesky.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://ethereum-holesky-rpc.publicnode.com",
      "verifyApiKey": "VJ5QB8IKE15XDDKC2MZXPB7X4V4PIZ4ZUJ",
      "storage": "0xdEf4e82De3932f93f93ce18Fe12C89495e6D5156"
    },

    // BSC
    "56": {
      "url": "https://bsc-mainnet.nodereal.io/v1/15d3297dca744d8f9ba4fdc883cd5067",
      "urlAlt": "https://bsc-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "verifyApiKey": "UPDXXY7TBM99134DHXB32ZKQAYNWA64J77",
      "storage": "0x205BF1cA117c77da148e8dF8a61C3155AF84FB2e"
    },
    // BSC Testnet
    "97": {
      "url": "https://bsc-testnet.nodereal.io/v1/15d3297dca744d8f9ba4fdc883cd5067",
      "urlAlt": "https://bsc-testnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "verifyApiKey": "UPDXXY7TBM99134DHXB32ZKQAYNWA64J77",
      "storage": "0xF9164ef7119D2adAE944A6f7Ca81936CBf573505"
    },

    // Polygon
    "137": {
      "url": "https://polygon-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://polygon.blockpi.network/v1/rpc/public",
      "verifyApiKey": "T7WFEPV18YA4763Y4DIUXXH4EIXKAUAV8C",
      "storage": "0xcB821BF6FbC9c63131C1B7c9922b58B637aF7370"
    },
    // Polygon Mumbai
    "80001": {
      "url": "https://polygon-testnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://polygon-mumbai.blockpi.network/v1/rpc/public",
      "verifyApiKey": "T7WFEPV18YA4763Y4DIUXXH4EIXKAUAV8C",
      "storage": "0xdEf4e82De3932f93f93ce18Fe12C89495e6D5156"
    },
    // Polygon Amoy
    "80002": {
      "url": "https://polygon-amoy.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://polygon-amoy.blockpi.network/v1/rpc/public",
      "verifyApiKey": "T7WFEPV18YA4763Y4DIUXXH4EIXKAUAV8C",
      "storage": "0xdEf4e82De3932f93f93ce18Fe12C89495e6D5156"
    },

    // Avalanche
    "43114": {
      "url": "https://ava-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1/ext/bc/C/rpc",
      "urlAlt": "https://avalanche.blockpi.network/v1/rpc/public",
      "verifyApiKey": "QA1DY3X7G5PHWHM9RYB4G4EJYM53RHTWY3",
      "storage": "0xcB821BF6FbC9c63131C1B7c9922b58B637aF7370"
    },
    // Avalanche Fuji
    "43113": {
      "url": "https://ava-testnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1/ext/bc/C/rpc",
      "urlAlt": "https://avalanche-fuji.blockpi.network/v1/rpc/public",
      "verifyApiKey": "QA1DY3X7G5PHWHM9RYB4G4EJYM53RHTWY3",
      "storage": "0xdEf4e82De3932f93f93ce18Fe12C89495e6D5156"
    },

    // Fantom
    "250": {
      "url": "https://fantom-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://fantom.blockpi.network/v1/rpc/public",
      "verifyApiKey": "9IUR7ZSNR946SZE5PSKE7U2MCJVD8DRQPV",
      "storage": "0xcB821BF6FbC9c63131C1B7c9922b58B637aF7370"
    },
    // Fantom Testnet
    "4002": {
      "url": "https://fantom-testnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://rpc.testnet.fantom.network",
      "verifyApiKey": "9IUR7ZSNR946SZE5PSKE7U2MCJVD8DRQPV",
      "storage": "0xdEf4e82De3932f93f93ce18Fe12C89495e6D5156"
    },

    // Arbitrum
    "42161": {
      "url": "https://arbitrum-one.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://arbitrum.blockpi.network/v1/rpc/public",
      "verifyApiKey": "R6NIKS61DAJ5MIPW859VZPD4KFB7U5UNPP",
      "storage": "0xcB821BF6FbC9c63131C1B7c9922b58B637aF7370"
    },

    // Optimism
    "10": {
      "url": "https://optimism-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://optimism.blockpi.network/v1/rpc/public",
      "verifyApiKey": "KHMXQ1WI3H1EISSP4Q396GHAWNFG9ETQBQ",
      "storage": "0xcB821BF6FbC9c63131C1B7c9922b58B637aF7370"
    },

    // Cronos
    "25": {
      "url": "https://evm.cronos.org",
      "urlAlt": "https://cronos.blockpi.network/v1/rpc/public",
      "verifyApiKey": "GJVW671W3TQTP3HFACSW4H2S5TBK7J6ND1",
      "storage": "0xdEf4e82De3932f93f93ce18Fe12C89495e6D5156"
    },
    
    // PulseChain
    "369": {
      "url": "https://rpc.pulsechain.com",
      "urlAlt": "https://pulsechain.publicnode.com",
      "verifyApiKey": "",
      "storage": "0x72cD67C331711Fff084be89cB2BF1F2f413cd8Cd"
    },

    // Base
    "8453": {
      "url": "https://base-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://developer-access-mainnet.base.org",
      "verifyApiKey": "ZA3WV64KUBZV99N3M4RFU89S58VPBI71EI",
      "storage": "0xfFDA554E5Bf7c2C15BFE1504780cb161Ef86cB84"
    },

    // Core
    "1116": {
      "url": "https://rpc.coredao.org",
      "urlAlt": "https://1rpc.io/core",
      "verifyApiKey": "",
      "storage": "0xcB821BF6FbC9c63131C1B7c9922b58B637aF7370"
    },

    // Evmos
    "9001": {
      "url": "https://evmos-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://evmos-evm.publicnode.com",
      "verifyApiKey": "",
      "storage": "0xdEf4e82De3932f93f93ce18Fe12C89495e6D5156"
    },

    // Linea
    "59144": {
      "url": "https://linea-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://linea.blockpi.network/v1/rpc/public",
      "verifyApiKey": "PSKJ1EY8Q11PZ46AWA9FMY2FPGSSYJRSND",
      "storage": "0xdEf4e82De3932f93f93ce18Fe12C89495e6D5156"
    },

    // Mantle
    "5000": {
      "url": "https://mantle-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://rpc.mantle.xyz",
      "verifyApiKey": "",
      "storage": "0xdEf4e82De3932f93f93ce18Fe12C89495e6D5156"
    },

    // Blast
    "81457": {
      "url": "https://blastl2-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://rpc.blast.io",
      "verifyApiKey": "TG8IHNI5646PAA9DP8WI3IW82GPP2CKF4B",
      "storage": "0xdEf4e82De3932f93f93ce18Fe12C89495e6D5156"
    },

    // DegenChain
    "666666666": {
      "url": "https://rpc.degen.tips",
      "urlAlt": "https://rpc.degen.tips",
      "verifyApiKey": "",
      "storage": "0xdEf4e82De3932f93f93ce18Fe12C89495e6D5156"
    },

    // Scroll
    "534352": {
      "url": "https://scroll-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://scroll.blockpi.network/v1/rpc/public",
      "verifyApiKey": "C1KRQRMSNGTA33I9ZW2UMTU47CE3PFDJG1",
      "storage": "0xdEf4e82De3932f93f93ce18Fe12C89495e6D5156"
    },

    // Metis
    "1088": {
      "url": "https://metis-mainnet.blastapi.io/07bd3ce1-e1fd-4198-811c-7f9f036f9ed1",
      "urlAlt": "https://metis-pokt.nodies.app",
      "verifyApiKey": "",
      "storage": "0xdEf4e82De3932f93f93ce18Fe12C89495e6D5156"
    }
  }
}