import React from "react";

import { ErrorBoundary } from "react-error-boundary";
import { Route, Routes, Navigate } from "react-router-dom";
import { BrowserRouter } from 'react-router-dom';

import {
  WagmiConfig,
  createConfig,
  configureChains
} from 'wagmi';
import { mainnet, bsc, polygon, fantom, avalanche, arbitrum, optimism, cronos, pulsechain, base, evmos, goerli, bscTestnet, polygonMumbai, avalancheFuji, fantomTestnet, linea, mantle, metis } from 'wagmi/chains'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { ConnectKitProvider, getDefaultConfig } from "connectkit";

import '../css/main.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Navbar from './components/Navbar';
import Withdraw from './Withdraw';
import Prices from './Prices';
import Codes from './Codes';
import Home from './Home';

const polygonAmoy = {
  id: 80_002,
  name: 'Polygon Amoy',
  network: 'amoy',
  nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://rpc-amoy.polygon.technology'] },
    public: { http: ['https://rpc-amoy.polygon.technology'] },
  },
  blockExplorers: {
    default: {
      name: 'PolygonScan',
      url: 'https://amoy.polygonscan.com',
      apiUrl: 'https://api-amoy.polygonscan.com/api',
    },
  },
  testnet: true,
}

const core = {
  id: 1116,
  name: 'Core',
  network: 'core',
  nativeCurrency: {
    "name": "Core Blockchain Native Token",
    "symbol": "CORE",
    "decimals": 18
  },
  rpcUrls: {
    default: { http: ['https://rpc.coredao.org'] },
    public: { http: ['https://rpc-core.icecreamswap.com'] },
  },
  blockExplorers: {
    default: { name: 'Core Scan', url: 'https://scan.coredao.org' },
  }
};

const blast = {
  id: 81457,
  name: 'Blast',
  network: 'blast',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: { http: ['https://rpc.blast.io'] },
    public: { http: ['https://rpc.blast.io'] },
  },
  blockExplorers: {
    default: {
      name: 'Blastscan',
      url: 'https://blastscan.io'
    },
  }
};

const degen = {
  id: 666666666,
  name: 'Degen',
  network: 'degen',
  nativeCurrency: {
    decimals: 18,
    name: 'Degen',
    symbol: 'DEGEN',
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.degen.tips'],
    },
    public: { http: ['https://rpc.degen.tips'] },
  },
  blockExplorers: {
    default: {
      name: 'Degen Chain Explorer',
      url: 'https://explorer.degen.tips',
    },
  },
}

const sepolia = {
  id: 11_155_111,
  name: 'Sepolia',
  nativeCurrency: { name: 'Sepolia Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.sepolia.org'],
    },
    public: { http: ['https://rpc.sepolia.org'] },
  },
  blockExplorers: {
    default: {
      name: 'Etherscan',
      url: 'https://sepolia.etherscan.io',
      apiUrl: 'https://api-sepolia.etherscan.io/api',
    },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 751532,
    },
    ensRegistry: { address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e' },
    ensUniversalResolver: {
      address: '0xc8Af999e38273D658BE1b921b88A9Ddf005769cC',
      blockCreated: 5_317_080,
    },
  },
  testnet: true,
}

const holesky = {
  id: 17000,
  name: 'Holesky',
  nativeCurrency: { name: 'Holesky Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://ethereum-holesky.publicnode.com'],
    },
    public: { http: ['https://ethereum-holesky.publicnode.com'] },
  },
  blockExplorers: {
    default: {
      name: 'Etherscan',
      url: 'https://holesky.etherscan.io',
    },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 77,
    },
    ensRegistry: {
      address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
      blockCreated: 801613,
    },
    ensUniversalResolver: {
      address: '0xa6AC935D4971E3CD133b950aE053bECD16fE7f3b',
      blockCreated: 973484,
    },
  },
  testnet: true,
}

const scroll = {
  id: 534_352,
  name: 'Scroll',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.scroll.io'],
    },
    public: { http: ['https://rpc.scroll.io'] },
  },
  blockExplorers: {
    default: {
      name: 'Scrollscan',
      url: 'https://scrollscan.com',
      apiUrl: 'https://api.scrollscan.com/api',
    },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 14,
    },
  },
  testnet: false,
}

const getFantomTestnet = () => {
  fantomTestnet.testnet = true;

  return fantomTestnet;
}

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [
    mainnet,
    arbitrum,
    optimism,
    base,
    linea,
    blast,
    scroll,
    
    bsc,
    polygon,
    fantom,
    avalanche,
    cronos,
    mantle,
    metis,
    
    pulsechain,
    core,
    evmos,
    degen,

    goerli,
    sepolia,
    holesky,
    bscTestnet,
    polygonMumbai,
    polygonAmoy,
    getFantomTestnet(),
    avalancheFuji
  ],
  [
    jsonRpcProvider({
      weight: 1,
      rpc: (chain) => {
        if (chain.id === 1) return {http: `https://ethereum.publicnode.com`}
        if (chain.id === 5) return {http: `https://ethereum-goerli.publicnode.com`}
        if (chain.id === 11155111) return {http: `https://ethereum-sepolia-rpc.publicnode.com`}
        if (chain.id === 17000) return {http: `https://ethereum-holesky-rpc.publicnode.com`}

        if (chain.id === 56) return {http: `https://bsc.publicnode.com`}
        if (chain.id === 97) return {http: `https://bsc-testnet.public.blastapi.io`}
          
        if (chain.id === 137) return {http: `https://polygon-bor.publicnode.com`}
        if (chain.id === 80001) return {http: `https://polygon-mumbai-bor.publicnode.com`}
        if (chain.id === 80002) return {http: `https://polygon-amoy-bor-rpc.publicnode.com`}

        if (chain.id === 43114) return {http: `https://avalanche-c-chain.publicnode.com`}
        if (chain.id === 43113) return {http: `https://avalanche-fuji-c-chain.publicnode.com`}

        if (chain.id === 250) return {http: `https://fantom.publicnode.com`}
        if (chain.id === 4002) return {http: `https://fantom-testnet.publicnode.com`}
        
        if (chain.id === 42161) return {http: `https://arbitrum.blockpi.network/v1/rpc/public`}
        if (chain.id === 10) return {http: `https://optimism.blockpi.network/v1/rpc/public`}
        if (chain.id === 25) return {http: `https://evm.cronos.org`}
        if (chain.id === 369) return {http: `https://rpc.pulsechain.com`}
        if (chain.id === 8453) return {http: `https://developer-access-mainnet.base.org`}
        if (chain.id === 59144) return {http: `https://linea.blockpi.network/v1/rpc/public`}
        if (chain.id === 5000) return {http: `https://rpc.mantle.xyz`}
        if (chain.id === 81457) return {http: `https://rpc.blast.io`}
        if (chain.id === 666666666) return {http: `https://rpc.degen.tips`}
        if (chain.id === 534352) return {http: `https://scroll.blockpi.network/v1/rpc/public`}
        if (chain.id === 1088) return {http: `https://metis-pokt.nodies.app`}
        
        if (chain.id === 1116) return {http: `https://rpc.coredao.org`}
        if (chain.id === 9001) return {http: `https://evmos-evm.publicnode.com`}
      }
    })
  ]
)

const config = createConfig(
  getDefaultConfig({
    // Required
    appName: "Admin dashboard",

    autoConnect: true,
    publicClient,
    webSocketPublicClient,
    chains
  }),
);

function fallbackRender({ error, resetErrorBoundary }) {
  return (
    <div>
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <ErrorBoundary fallbackRender={fallbackRender}>
        <WagmiConfig config={config}>
          <ConnectKitProvider options={{
            initialChainId: 0,
          }}>
            <div className="overlay">
              <Navbar />
              <Routes>
                <Route path="/prices" element={<Prices />} />
                <Route path="/codes" element={<Codes />} />
                <Route path="/withdraw" element={<Withdraw />} />
                
                <Route path="/" element={<Home />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
              <ToastContainer theme="dark" draggable={false} closeOnClick={false} />
            </div>
          </ConnectKitProvider>
        </WagmiConfig>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
