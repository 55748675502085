import Nav from 'react-bootstrap/Nav';
import { Link } from "react-router-dom";
import { ConnectKitButton } from "connectkit";

function Navbar() {
  return (
    <Nav
      className="justify-content-center navbar"
      activeKey="/home"
    >
      <Nav.Item>
        <Link to="/">Home</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/prices">Prices</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/codes">Codes</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/withdraw">Withdraw</Link>
      </Nav.Item>
      <Nav.Item>
        <ConnectKitButton />
      </Nav.Item>
    </Nav>
  );
}

export default Navbar;