import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useNetwork, useAccount } from 'wagmi';

const Home = () => {

	const { chain } = useNetwork();
	const { address } = useAccount();

	return (
		<>
			<Container className="container">
				<Row>
				{chain && chain.id && !chain.unsupported && (address == process.env.REACT_APP_ADDRESS || address == process.env.REACT_APP_ADDRESS2) ?
					<Col>Admin connected.</Col>
					: <Col>Admin wallet required to connect…</Col>
				}
				</Row>
			</Container>
		</>
	);
};

export default Home;
