import React, { useState, useEffect } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useNetwork, useAccount, useWalletClient, useSwitchNetwork } from 'wagmi';
import { getPublicClient } from 'wagmi/actions';
import { formatEther, parseEther, keccak256, stringToBytes } from 'viem';

import { toast } from 'react-toastify';
import axios from 'axios';

import chainsData from "../utils/chains.js";
import translations from "../utils/translations.json";

const ethBased = [1, 10, 42161, 8453, 59144, 81457, 534352];

const CodesOverlay = () => {

  const { chain } = useNetwork()
  const { address } = useAccount()
  
  return (
    <>
      {chain && chain.id && !chain.unsupported && (address == process.env.REACT_APP_ADDRESS || address == process.env.REACT_APP_ADDRESS2) ?
        <Codes/>
        : <Container className="container">
          <span>Admin wallet required to connect…</span>
        </Container>
      }
    </>
  )
}

const ToastCodeInfo = ({chainName, formData}) => {
  if (!formData.code) {
    const expiration = (new Date(parseInt((formData[2]*1000n).toString()))).toUTCString();
    return (<>
      -- Code info --<br/>
      Blockchain: {chainName}<br/>
      Discount: -{parseFloat((formData[0] / 10n).toString())}%<br/>
      Uses: {formData[1]}<br/>
      Expires: {expiration}<br/>
      UsesPerAddress: {formData[3]}<br/>
      AffectionMask: {parseInt(formData[4])}<br/>
      DeployerRule: {formData[5]}
    </>);
  } else 
    return (<>
      -- Code info --<br/>
      Code: {formData.code}<br/>
      Code hash: {formData.codeHash}<br/>
      Blockchain: {chainName}<br/>
      Discount: -{Math.round(formData.discount / 10)}%<br/>
      Uses: {formData.uses}<br/>
      Expires: {formData.expiry}<br/>
      UsesPerAddress: {formData.usesPerAddress}<br/>
      AffectionMask: {formData.affectionMask}<br/>
      DeployerRule: {formData.deployerRule}
    </>);
};

const Codes = () => {

  const { chain } = useNetwork()
  const { address } = useAccount()
  const { chains, error, isLoading, pendingChainId, switchNetwork } = useSwitchNetwork()

  const { data:walletClient } = useWalletClient();
  const publicClient = getPublicClient();

  const [formData, setFormData] = useState({
    code: "",
    codeHash: "",
    discount: 500,
    uses: 1,
    expiry: "",
    usesPerAddress: 1,
    affectionMask: 1,
    deployerRule: "0"
  });

  const [formRemoveData, setFormRemoveData] = useState({
    codeHash: "",
  });

  const [checkCodeData, setCheckCodeData] = useState({
    selectCodeCheck: "c",
    checkCode: "",
    checkCodeHash: ""
  });

  const ab = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const getRandomCode = () => {
    let res = "";

    for (let i=0; i<40; i++) {
      res = res + ab[Math.floor(Math.random() * 36)];
    }

    return res;
  }

  const regenerate = () => {
    setFormData({...formData, code: getRandomCode()});
  }

  const onChange = (e) => {
    let data = {...formData};

    data[e.target.name] = e.target.value;

    setFormData(data);
  }

  const unmask = (mask) => {
    let result = "";
    for (let i=0; i<20; i++) {
      if ((mask & (1 << i)) !== 0) result = result+i.toString()+" ";
    }
    return result;
  }

  const onChange2 = (e) => {
    let data = {...formRemoveData};

    data[e.target.name] = e.target.value;

    setFormRemoveData(data);
  }

  const onChange3 = (e) => {
    let data = {...checkCodeData};

    data[e.target.name] = e.target.value;

    setCheckCodeData(data);
  }

  const editCode = async (chainId) => {
    try {
      let request = (await publicClient.simulateContract({
        account: address,
        address: chainsData.public[chainId].rc,
        abi: chainsData.public[chainId].rcAbi,
        functionName: 'editCodes',
        args: [[formData.codeHash], [formData.discount], [formData.uses], [(new Date(formData.expiry)).getTime()/1000], [formData.usesPerAddress], [formData.affectionMask], [formData.deployerRule]]
      })).request;

      const hash = await walletClient.writeContract(request);

      toast.success(`Code edited on ${chainId}: ${hash}`, {
        autoClose: false,
      });
      toast.info(<ToastCodeInfo chainName={translations[chainId]} formData={formData} />, {
        autoClose: false,
      });
    } catch (e) {
      toast.error(e.shortMessage);
      console.log(e);
    }
  }

  const removeCode = async (chainId) => {
    try {
      let request = (await publicClient.simulateContract({
        account: address,
        address: chainsData.public[chainId].rc,
        abi: chainsData.public[chainId].rcAbi,
        functionName: 'removeCodes',
        args: [[formRemoveData.codeHash]]
      })).request;

      const hash = await walletClient.writeContract(request);

      toast.success(`Code removed on ${chainId}: ${hash}`, {
        autoClose: false,
      });
    } catch (e) {
      toast.error(e.shortMessage);
      console.log(e);
    }
  }

  const checkCode = async (chainId) => {
    try {
      let data;

      if (checkCodeData.selectCodeCheck === "c") {
        data = await publicClient.readContract({
          address: chainsData.public[chainId].rc,
          abi: chainsData.public[chainId].rcAbi,
          functionName: 'getDiscountCodes',
          args: [keccak256(stringToBytes(checkCodeData.checkCode))]
        });
      } else if (checkCodeData.selectCodeCheck === "ch") {
        data = await publicClient.readContract({
          address: chainsData.public[chainId].rc,
          abi: chainsData.public[chainId].rcAbi,
          functionName: 'getDiscountCodes',
          args: [checkCodeData.checkCodeHash]
        });
      }

      toast.success(<ToastCodeInfo chainName={translations[chainId]} formData={data} />, {
        autoClose: false,
      });
    } catch (e) {
      toast.error(e.shortMessage);
      console.log(e);
    }
  }

  useEffect(() => {
    setFormData({...formData, codeHash: keccak256(stringToBytes(formData.code))});
  }, [formData.code]);

  return (
    <>
      <Container className="container">
        <h1 className="text-center">CODES</h1>
        {chain && chain.id && !chain.unsupported && (address == process.env.REACT_APP_ADDRESS || address == process.env.REACT_APP_ADDRESS2) ? chains.map((ch, key) => {

          return (
            <div key={key} className={`display-element ${chainsData.public[ch.id].testnet ? "d-testnet" : ""} ${ethBased.includes(ch.id) ? "d-eth" : ""}`}>
              <span>{`${translations[ch.id]} (${ch.id})`}</span>
              {ch.id === chain.id && <Row className="p-2">
                <Col>
                  <div className="fixed-h">CODE</div>
                  <div className="fixed-h">CODE HASH</div>
                  <div className="fixed-h">TOTAL USES</div>
                  <div className="fixed-h">DISCOUNT</div>
                  <div className="fixed-h">EXPIRATION</div>
                  <div className="fixed-h">USES PER ADDRESS</div>
                  <div className="fixed-h">AFFECTION MASK</div>
                  <div className="fixed-h">DEPLOYER RULE</div>
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    className="input-segment"
                    placeholder="Code"
                    name="code"
                    value={formData.code}
                    onChange={onChange}
                  />
                  <Form.Control
                    type="text"
                    className="input-segment my-2"
                    disabled={true}
                    placeholder="Code Hash"
                    name="codeHash"
                    value={formData.codeHash}
                    onChange={onChange}
                  />
                  <Form.Control
                    type="text"
                    className="input-segment my-2"
                    placeholder="Total uses"
                    name="uses"
                    value={formData.uses}
                    onChange={onChange}
                  />
                  <Form.Control
                    type="text"
                    className="input-segment my-2"
                    placeholder="Discount"
                    name="discount"
                    value={formData.discount}
                    onChange={onChange}
                  />
                  <Form.Control
                    type="date"
                    className="input-segment my-2"
                    placeholder="Expiry"
                    name="expiry"
                    value={formData.expiry}
                    onChange={onChange}
                  />
                  <Form.Control
                    type="number"
                    min="0"
                    className="input-segment my-2"
                    placeholder="Uses per address"
                    name="usesPerAddress"
                    value={formData.usesPerAddress}
                    onChange={onChange}
                  />
                  <Form.Control
                    type="number"
                    min="0"
                    className="input-segment my-2"
                    placeholder="Affection Mask"
                    name="affectionMask"
                    value={formData.affectionMask}
                    onChange={onChange}
                  />
                  <Form.Control
                    type="number"
                    min="0"
                    max="3"
                    className="input-segment my-2"
                    placeholder="Deployer Rule"
                    name="deployerRule"
                    value={formData.deployerRule}
                    onChange={onChange}
                  />
                  <Button
                    className="mt-2 w-100"
                    onClick={() => editCode(chain.id)}
                  >
                    Edit Code
                  </Button>
                </Col>
                <Col>
                  <div>
                    <Button
                      className="w-50 btn-secondary"
                      onClick={() => regenerate()}
                    >
                      Re-generate
                    </Button>
                  </div>
                  <div className="fixed-h"></div>
                  <div className="fixed-h"></div>
                  <div className="fixed-h">-{formData.discount / 10}%</div>
                  <div className="fixed-h">{`${(new Date(formData.expiry)).toUTCString()} --- Timestamp: ${(new Date(formData.expiry)).getTime()/1000}`}</div>
                  <div className="fixed-h"></div>
                  <div className="fixed-h">{`Works on cTypes: ${unmask(formData.affectionMask)}`}</div>
                  <div className="fixed-h">Deployer history:
                    {formData.deployerRule === "0" && " None"}
                    {formData.deployerRule === "1" && " Token"}
                    {formData.deployerRule === "2" && " Peripheral"}
                    {formData.deployerRule === "3" && " Both"}
                  </div>
                </Col>
              </Row>}


              {ch.id === chain.id && <Row className="p-2 my-5">
                <Col>
                  <div className="fixed-h">CODE</div>
                </Col>
                <Col xs={8}>
                  <Form.Control
                    type="text"
                    className="input-segment my-2"
                    placeholder="Code Hash"
                    name="codeHash"
                    value={formRemoveData.codeHash}
                    onChange={onChange2}
                  />
                  <Button
                    className="mt-2 w-100"
                    onClick={() => removeCode(chain.id)}
                  >
                    Remove Code
                  </Button>
                </Col>
              </Row>}

              {ch.id === chain.id && <Row className="p-2 my-5">
                <Col>
                  <div className="fixed-h">CHECK CODE</div>
                </Col>
                <Col xs={8}>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={onChange3}
                    name="selectCodeCheck"
                  >
                    <option value="c">Code</option>
                    <option value="ch">CodeHash</option>
                  </Form.Select>
                  {checkCodeData.selectCodeCheck === "c" && <Form.Control
                    type="text"
                    className="input-segment my-2"
                    placeholder="Code"
                    name="checkCode"
                    value={checkCodeData.checkCode}
                    onChange={onChange3}
                  />}
                  {checkCodeData.selectCodeCheck === "ch" && <Form.Control
                    type="text"
                    className="input-segment my-2"
                    placeholder="Code Hash"
                    name="checkCodeHash"
                    value={checkCodeData.checkCodeHash}
                    onChange={onChange3}
                  />}
                  <Button
                    className="mt-2 w-100"
                    onClick={() => checkCode(chain.id)}
                  >
                    Check code
                  </Button>
                </Col>
              </Row>}



              <div className="p-2"><Button
                className="btn-info"
                disabled={!switchNetwork || ch.id === chain.id}
                onClick={() => switchNetwork(ch.id)}
              >
                {ch.name}
                {isLoading && pendingChainId === ch.id && ' (switching)'}
              </Button></div>
            </div>
          );
        }) :
          <span>Admin wallet required to connect…</span>
        }
      </Container>
    </>
  );
};

export default CodesOverlay;