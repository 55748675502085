import React, { useState, useEffect } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useNetwork, useAccount, useWalletClient, useSwitchNetwork } from 'wagmi';
import { getPublicClient } from 'wagmi/actions';
import { formatEther, parseEther } from 'viem';

import { toast } from 'react-toastify';
import axios from 'axios';

import chainsData from "../utils/chains.js";
import translations from "../utils/translations.json";

const geckoChains = {
  "1": "ethereum",
  "5": "testnet",
  "11155111": "testnet",
  "17000": "testnet",

  "56": "binancecoin",
  "97": "testnet",

  "137": "matic-network",
  "80001": "testnet",
  "80002": "testnet",

  "43114": "avalanche-2",
  "43113": "testnet",

  "250": "fantom",
  "4002": "testnet",
  
  "42161": "ethereum",
  "10": "ethereum",
  "25": "wrapped-cro",
  "369": "pulsechain",
  "8453": "ethereum",
  "1116": "coredaoorg",
  "9001": "evmos",

  "59144": "ethereum",
  "5000": "mantle",
  "81457": "ethereum",
  "666666666": "degen-base",
  "534352": "ethereum",
  "1088": "metis-token"
};

const ethBased = [1, 10, 42161, 8453, 59144, 81457, 534352];

const PricesOverlay = () => {

  const { chain } = useNetwork()
  const { address } = useAccount()

  const [pricesCG, setPricesCG] = useState();

  const fetchCG = async () => {
    try {
      const priceData = await axios.get(`https://api.coingecko.com/api/v3/simple/price`, { params: {
        ids: "ethereum,binancecoin,matic-network,fantom,avalanche-2,wrapped-cro,evmos,coredaoorg,pulsechain,mantle,degen-base,metis-token",
        vs_currencies: "usd",
        include_last_updated_at: true
      }});
      setPricesCG({...priceData.data, testnet: {usd: "0"}});
    } catch (ex) {
      toast.error("Cannot fetch CoinGecko prices");
    }
  }

  useEffect(() => {
    if (chain && chain.id && !chain.unsupported && (address == process.env.REACT_APP_ADDRESS || address == process.env.REACT_APP_ADDRESS2)) fetchCG();
  }, []);

  return (
    <>
      {chain && chain.id && !chain.unsupported && (address == process.env.REACT_APP_ADDRESS || address == process.env.REACT_APP_ADDRESS2) && pricesCG ?
        <Prices pricesCG={pricesCG}/>
        : <Container className="container">
          <span>Admin wallet required to connect…</span>
        </Container>
      }
    </>
  )
}

const Prices = ({ pricesCG }) => {

  const { chain } = useNetwork()
  const { address } = useAccount()
  const { chains, error, isLoading, pendingChainId, switchNetwork } = useSwitchNetwork()

  const { data:walletClient } = useWalletClient();
  const publicClient = getPublicClient();

  const [priceData, setPriceData] = useState({});
  const [formData, setFormData] = useState({
    priceUSD: "0"
  });

  const onChange = (e) => {
    let data = {...formData};

    data[e.target.name] = e.target.value;

    setFormData(data);
  }

  const getPrice = async (registry, abi) => {
    const dep = await publicClient.readContract({
      address: registry,
      abi: abi,
      functionName: 'getDeploymentPrice',
      args: [0]
    })

    const cw = await publicClient.readContract({
      address: registry,
      abi: abi,
      functionName: 'getCustomWatermarkPrice',
      args: [0]
    })

    const pUSD = await publicClient.readContract({
      address: registry,
      abi: abi,
      functionName: 'valueUSD'
    })

    const depUSD = await publicClient.readContract({
      address: registry,
      abi: abi,
      functionName: 'deploymentPrices',
      args: [0]
    })

    const cwUSD = await publicClient.readContract({
      address: registry,
      abi: abi,
      functionName: 'customWatermarkPrices',
      args: [0]
    })

    setPriceData({...priceData, [chain.id]: {dep, cw, pUSD, depUSD, cwUSD}});
    setFormData({priceUSD: formatEther(pUSD)});
  }

  const write = async (chainId) => {
    try {
      let request = (await publicClient.simulateContract({
        account: address,
        address: chainsData.public[chainId].rc,
        abi: chainsData.public[chainId].rcAbi,
        functionName: 'setPriceUSD',
        args: [parseEther(formData.priceUSD)]
      })).request;

      const hash = await walletClient.writeContract(request);

      toast.success(`Prices changed on ${chainId}: ${hash}`, {
        autoClose: false,
      });
    } catch (e) {
      toast.error(e.shortMessage);
      console.log(e);
    }
  }

  useEffect(() => {
    if (chain && chain.id && !chain.unsupported) {
      getPrice(chainsData.public[chain.id].rc, chainsData.public[chain.id].rcAbi);
    }
  }, [chain]);

  return (
    <>
      <Container className="container">
        <h1 className="text-center">PRICES</h1>
        {chain && chain.id && !chain.unsupported && (address == process.env.REACT_APP_ADDRESS || address == process.env.REACT_APP_ADDRESS2) ? chains.map((ch, key) => {

          return (
            <div key={key} className={`display-element ${chainsData.public[ch.id].testnet ? "d-testnet" : ""} ${ethBased.includes(ch.id) ? "d-eth" : ""}`}>
              <span>{`${translations[ch.id]} (${ch.id})`}</span>
              {priceData[ch.id] && <Row className="p-2">
                <Col>
                  {priceData[ch.id].dep && <div>{`Dep: ${formatEther(priceData[ch.id].dep)} ${ch.nativeCurrency.symbol}`}</div>}
                  {priceData[ch.id].cw && <div>{`CW: ${formatEther(priceData[ch.id].cw)} ${ch.nativeCurrency.symbol}`}</div>}
                  {priceData[ch.id].dep && priceData[ch.id].cw && <div>{`Total: ${formatEther(priceData[ch.id].dep + priceData[ch.id].cw)} ${ch.nativeCurrency.symbol}`}</div>}
                  {priceData[ch.id].pUSD && <div style={{fontWeight: "700"}}>{`PriceUSD: ${formatEther(priceData[ch.id].pUSD)} ${ch.nativeCurrency.symbol}`}</div>}
                  {ch.id === chain.id && <Button
                    className="mt-2 w-100 btn-secondary"
                    onClick={() => getPrice(chainsData.public[ch.id].rc, chainsData.public[ch.id].rcAbi)}
                  >
                    Refresh
                  </Button>}
                </Col>
                <Col>
                  {priceData[ch.id].dep && <div>{`USD: ${pricesCG[geckoChains[ch.id]].usd * parseFloat(formatEther(priceData[ch.id].dep))}$`}</div>}
                  {priceData[ch.id].cw && <div>{`USD: ${pricesCG[geckoChains[ch.id]].usd * parseFloat(formatEther(priceData[ch.id].cw))}$`}</div>}
                  {priceData[ch.id].dep && priceData[ch.id].cw && <div>{`Total: ${pricesCG[geckoChains[ch.id]].usd * parseFloat(formatEther(priceData[ch.id].dep + priceData[ch.id].cw))}$`}</div>}
                </Col>
                {ch.id === chain.id ? <Col>
                  <Form.Control
                    type="text"
                    className="input-segment"
                    placeholder="PriceUSD"
                    name="priceUSD"
                    value={formData.priceUSD}
                    onChange={onChange}
                  />
                  {formData.priceUSD && <div style={{fontWeight: "700"}}>{`PriceUSD: ${parseFloat(formData.priceUSD)} ${ch.nativeCurrency.symbol}`}</div>}
                  <Button
                    className="mt-2 w-100"
                    onClick={() => write(chain.id)}
                  >
                    Set USD Price
                  </Button>
                </Col> : <Col></Col>}
                {ch.id === chain.id ? <Col>
                  {priceData[ch.id].depUSD && formData.priceUSD && <div>{`New dep: ${parseFloat(formData.priceUSD) * parseFloat(priceData[ch.id].depUSD)} ${ch.nativeCurrency.symbol}`}</div>}
                  {priceData[ch.id].cwUSD && formData.priceUSD && <div>{`New cw: ${parseFloat(formData.priceUSD) * parseFloat(priceData[ch.id].cwUSD)} ${ch.nativeCurrency.symbol}`}</div>}
                  {priceData[ch.id].depUSD && priceData[ch.id].cwUSD && formData.priceUSD && <div>{`New total: ${parseFloat(formData.priceUSD) * parseFloat(priceData[ch.id].depUSD + priceData[ch.id].cwUSD)} ${ch.nativeCurrency.symbol}`}</div>}
                </Col> : <Col></Col>}
                {ch.id === chain.id ? <Col>
                  {priceData[ch.id].depUSD && formData.priceUSD && <div>{`New dep USD: ${pricesCG[geckoChains[ch.id]].usd * parseFloat(formData.priceUSD) * parseFloat(priceData[ch.id].depUSD)}$`}</div>}
                  {priceData[ch.id].cwUSD && formData.priceUSD && <div>{`New cw USD: ${pricesCG[geckoChains[ch.id]].usd * parseFloat(formData.priceUSD) * parseFloat(priceData[ch.id].cwUSD)}$`}</div>}
                  {priceData[ch.id].depUSD && priceData[ch.id].cwUSD && formData.priceUSD && <div>{`New total: ${pricesCG[geckoChains[ch.id]].usd * parseFloat(formData.priceUSD) * parseFloat(priceData[ch.id].depUSD + priceData[ch.id].cwUSD)}$`}</div>}
                </Col> : <Col></Col>}
              </Row>}
              <div className="p-2"><Button
                className="btn-info"
                disabled={!switchNetwork || ch.id === chain.id}
                onClick={() => switchNetwork(ch.id)}
              >
                {ch.name}
                {isLoading && pendingChainId === ch.id && ' (switching)'}
              </Button></div>
            </div>
          );
        }) :
          <span>Admin wallet required to connect…</span>
        }
      </Container>
    </>
  );
};

export default PricesOverlay;